import React, { useState, useEffect } from "react";
import "./DashboardScreen.css";
import  firebaseApp from "../firebase";
import "firebase/compat/firestore";
import GoToTop from "../GoToTop";
import arrowdown from "../assets/arrow-down.svg";
import logo from "../assets/Redground-oldlogo.png";

function DashboardScreen() {
    const db = firebaseApp.firestore();
    const [collectionData, setCollectionData] = useState([]);

    useEffect(() => {
        const collectionRef = db.collection('visitor_data');
    
        const unsubscribe = collectionRef.onSnapshot((snapshot) => {
          const data = [];
          snapshot.forEach((doc) => {
            data.push({ id: doc.id, ...doc.data() });
          });
          setCollectionData(data);
        });
    
        return () => unsubscribe();
      }, []);

    const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds

    const dateFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    };

    const timeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    };

    const formattedDate = date.toLocaleDateString('en-US', dateFormatOptions);
    const formattedTime = date.toLocaleTimeString('en-US', timeFormatOptions);
    const formattedDateTime = `${formattedDate} ${formattedTime}`;

    return formattedDateTime;
    };
    

    return (
        <div>
            <div className='dashboardScreen'>
            <header className="dashboardScreen__banner"
            style={{
                backgroundSize: "cover",
                backgroundImage: `linear-gradient(to top, #111 0%, transparent 90%), linear-gradient(to right, darkred, transparent), 
                     url(
                    "https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcover%2Fmars_cover.webp?alt=media&token=e91c8978-6026-446d-886f-b41574632be4"
                )`, 
                backgroundPosition: "center center",
            }}>
            <div className='dashboardScreen__content'>
                <div className="dashboardScreen__banner__content" >
                    <div className="dashboardScreen__banner__content__logo">
                        <img
                        src={logo}
                        alt=''
                        />
                    </div>
                    <h1>Data Control Dashboard</h1>
                    <img 
                        className='dashboardScreen__back__img'
                        src={arrowdown}
                        alt=""
                    />
                </div>
            </div>
            </header>

            <div className="dashboardScreen__list">
                <h2>Registration of applicants for early access to the adjudication process:</h2>
                <ul>
                    {collectionData.map((item, index) => (
                    <li key={item.id}>
                        <p>{index+1}</p>
                        <p>Id: <span>{item.id}</span></p>
                        <p>Firstname: <span>{item.nombre}</span></p>
                        <p>Lastname: <span>{item.apellidos}</span></p>
                        <p>Email: <span>{item.email}</span></p>
                        {item.timestamp &&
                           <p>Registration date: <span>{formatTimestamp(item.timestamp)}</span></p>
                        }
                    </li>
                    ))}
                </ul>
            </div>

            <div className="dashboardScreen__form__copyright">
                <p>2023 Redground Inc. :: All rights reserved.</p>
            </div>

            </div>
        <GoToTop/>
        </div>
    )
}

export default DashboardScreen;