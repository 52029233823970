import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import DashboardScreen from './screens/DashboardScreen';

function App() {
  return (
    <div className="app">
    <Router onUpdate={() => window.scrollTo(0, 0)}>
      {
        <Routes>
          <Route exact path="/" element={<DashboardScreen />} />
        </Routes>
      }
      </Router>
    </div>
  );
}

export default App;